import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  ModalHeader,
} from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"
import { Link } from "react-router-dom"

const DataTableAvisTest = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [idDelete, setIdDelete] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])
  const [jourId, setJourId] = useState("")
  const [quartId, setQuartId] = useState("")
  const [modalRefuse, setModalRefuse] = useState(false)
  const [cause, setCause] = useState("")
  const [idRefus, setIdRefus] = useState("")

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: 40,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "quart",
      text: props.t("Quart"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matiere"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_examen",
      text: props.t("Type examen"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: props.t("Salle"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: "Action",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.active == 0 ? (
          <div style={{ textAlign: "center" }}>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil"
                  id="edittooltip"
                  onClick={() => toggle(row.id)}
                />
              </Link>
              <Link to="#" className="text-danger">
                <i
                  className="fas fa-times"
                  id="deletetooltip"
                  onClick={() => toggleRefuse(row.id)}
                />
              </Link>
            </div>
          </div>
        ) : (
          <i
            style={{ color: "#2ca67a", cursor: "pointer" }}
            className="fas fa-check"
          ></i>
        ),
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "#ED6464", cursor: "pointer" }}
            onClick={() => toggleDelete(row.id)}
            className="fas fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("avis/examen/liste").then(res => {
      setOrders(res.data.list)
      if (res.data.list.length != 0) {
        setJourId(res.data.list.jour_id)
        setQuartId(res.data.list.quart_id)
      }
    })
    setLoading(true)
  }, [])

  const toggle = async id => {
    setModal(true)
    setId(id)
    const resS = await API.post("emploi/get_salle", {
      quart_id: quartId,
      jour_id: jourId,
      quan: 0,
    }).then(resS => {
      setSalle(resS.data.salles)
    })
  }

  const toggleRefuse = async id => {
    setModalRefuse(true)
    setIdRefus(id)
  }

  const toggleDelete = id => {
    setModalDelete(!modalDelete)
    setIdDelete(id)
  }

  const toggleDeleteRat = async () => {
    const resD = await API.post("avis/exmane/delete", {
      id: idDelete,
    }).then(resD => {
      setModalDelete(false)
      const res = API.get("avis/examen/liste").then(res => {
        setOrders(res.data.list)
        if (res.data.list.length != 0) {
          setJourId(res.data.list[0].jour_id)
          setQuartId(res.data.list[0].quart_id)
        }
      })
      setLoading(true)
    })
  }

  const close = () => {
    setModal(false)
  }

  const closeRefuse = () => {
    setModalRefuse(false)
  }

  const valide = async () => {
    setModal(false)
    setLoading(false)
    if (selectSalle != "") {
      const res = await API.post("avis/examen/valide", {
        id: id,
        etat: 1,
        salle_id: selectSalle.value,
      }).then(res => {
        const resN = API.post("avis/examen/notify_success", {
          id: id,
        }).then(resN => {
          setModal(false)
          const resD = API.get("avis/examen/liste").then(resD => {
            setOrders(resD.data.list)
            setLoading(true)
          })
        })
      })
    } else {
      toast.error("⛔ Salle obligatoire !", {
        containerId: "A",
      })
    }
  }

  const refuse = async () => {
    setModal(false)
    setLoading(false)
    const res = await API.post("avis/examen/notif_decline", {
      id: idRefus,
      cause: cause,
    }).then(res => {
      const resN = API.post("avis/rattrapage/delete", {
        id: idRefus,
      }).then(resN => {
        setModal(false)
        const resD = API.get("avis/rattrapage/list").then(resD => {
          setOrders(resD.data.list)
          setLoading(true)
        })
      })
    })
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={close} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={close}
        >
          {props.t("Validation rattrapage ")}
        </div>
        <ModalBody>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Salle</Label>
                <Select
                  options={salle}
                  isSearchable={true}
                  onChange={e => setSelectSalle(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-success" onClick={valide}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  {props.t("Valider")}
                </Button>
              </div>
              <div>
                <Button onClick={close} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRefuse} toggle={closeRefuse} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={closeRefuse}
        >
          {props.t("Refus rattrapage ")}
        </div>
        <ModalBody>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cause : </Label>
                <Input
                  lg="3"
                  className="form-control"
                  placeholder="Cause ..."
                  type="textarea"
                  onChange={e => setCause(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-danger" onClick={refuse}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  {props.t("Refuser")}
                </Button>
              </div>
              <div>
                <Button onClick={closeRefuse} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleDelete}
          tag="h4"
        >
          {"Suppression avis test"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette avis de test ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDeleteRat}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDelete}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableAvisTest))
DataTableAvisTest.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
