import React, {useState, useEffect, Fragment} from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";


const PaiementEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [typePiement, setTypePiement] = useState([]);
  const [selectTypePaiement, setSelectTypePaiement] = useState("");
  const [montant, setMontant] = useState("");
  const [numeroCheque, setNumeroCheque] = useState("");
  const [responsableCheque, setResponsableCheque] = useState("");
  const [dateCheque, setDateCheque] = useState("");
  const [nameBnque, setNameBnque] = useState("");
  const [etat, setEtat] = useState("none");
  const [montantTotal, setMontantTotal] = useState("");
  const [montantReste, setMontantReste] = useState("");
  const [montantPayee, setMontantPayee] = useState("");
  const [detail, setDetail] = useState("");
  const [prixSpeciale, setPrixSpeciale] = useState("");
  const [etatprix, setEtatprix] = useState("none");
  const [specialite, setSpecialite] = useState([]);
  const [selectSpecialite, setSelectSpecialite] = useState("");
  const [group, setGroup] = useState([]);
  const [selectGroup, setSelectGroup] = useState("");
  const [arrayDetail, setArrayDetail] = useState([])
  useEffect(async () => {
    API.get("type_payment/selectlist").then(resC => {
      setTypePiement(resC.data.type_payment)
    })

    setLoading(true)
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)

    API.post("etudiant/get_specialite",{annee_id,id}).then(resC => {
      setSpecialite(resC.data.specialite)
    })
    API.post("etudiant/get_detail_paiement",{annee_id,id}).then(resDetail => {
      setArrayDetail(resDetail.data.Detail)
    })
  }, [])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const getDetail = async event => {
    setSelectGroup(event)
    const res = await API.post("payment/get_detail_paiment_by_etudiant_id", {
      etudiant_id: id,
      specialite_id:selectSpecialite.value,
      group_id:event.value
    }).then(res => {
      setMontantTotal(res.data.Payment.prix_total)
      setMontantReste(res.data.Payment.prix_restant)
      setMontantPayee(res.data.Payment.prix_total_payer)
      setDetail(res.data.Payment.type_prix)
      setPrixSpeciale(res.data.Payment.prix_total)
    })
    // const resGroup = await API.post("etudiant/get_group", {
    //   etudiant_id: id,
    //   annee_id:annee_id
    // }).then(resGroup => {
    //     setGroup(resGroup.data.GroupEtudiant)
    // })
  }
  const getGroup = async event => {
    setSelectSpecialite(event)
    setSelectGroup("")
    setSelectTypePaiement("")
    setMontant("")
    setMontantTotal("")
    setMontantPayee("")
    setMontantReste("")
    setDetail("1")
    const resGroup = await API.post("etudiant/get_group", {
      etudiant_id: id,
      annee_id:annee_id,
      specialite_id:event.value
    }).then(resGroup => {
        setGroup(resGroup.data.GroupEtudiant)
    })
  }
  const getType = async event => {
    setSelectTypePaiement(event)
    if(event.value == 2){
      setEtat("none")
    }else if(event.value == 3){
      setEtat("")
    }
  }
  const getPrix = async event => {
    setDetail(event)
    if(event == 1){
      const res = await API.post("payment/get_detail_paiment_by_etudiant_id", {
        etudiant_id: id,
        specialite_id:selectSpecialite.value
      }).then(res => {
        setMontantTotal(res.data.Payment.prix_total)
        setMontantReste(res.data.Payment.prix_restant)
        setMontantPayee(res.data.Payment.prix_total_payer)
        setPrixSpeciale(res.data.Payment.prix_total)
      })
      setEtatprix("none")
    }else{
      setPrixSpeciale("")
      setEtatprix("")
    }
  }
  const handleKeyUp = (event) => {
    if(montantPayee > event.target.value){
      toast.error("⛔ Veuillez choisir un montant supérieur à total payée", {
        containerId: "A",
      })
    }else{
      setPrixSpeciale(event.target.value)
      setMontantTotal(event.target.value)
      setMontantReste(event.target.value-montantPayee)
    }

  };
  const save = async () => {
    if (dateCheque != "") {
      let dateComm = dateCheque
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }

    if (selectTypePaiement == "") {
      toast.error("⛔ Veuillez choisir un Type de paiement", {
        containerId: "A",
      })
    } else if (montant == "") {
      toast.error("⛔ Veuillez saisir un montant ", {
        containerId: "A",
      })
    }else if (selectSpecialite == "") {
      toast.error("⛔ Spécialité obligatoire ! ", {
        containerId: "A",
      })
    }
    if(selectTypePaiement.value == 2){
      const res = await API.post("payment/add", {
        user_id:user_id,
        etudiant_id:id,
        prix:montant,
        type_payment_id:selectTypePaiement.value,
        type_prix:detail,
        prix_special:prixSpeciale,
        specialite_id:selectSpecialite.value,
        group_id:selectGroup.value
      }).then(res => {
        if(res.data.errNum ==400){
          toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
            containerId: "A",
          })
        }else{
          props.history.push("/Etudiant")
        }
      })
    }else if(selectTypePaiement.value == 3){
      if (convertDate == "") {
        toast.error("⛔ Veuillez choisir un une date de chèque ", {
          containerId: "A",
        })
      }else if(numeroCheque == ""){
        toast.error("⛔ Veuillez saisir un numéro de chèque ", {
          containerId: "A",
        })
      }else if(responsableCheque == ""){
        toast.error("⛔ Veuillez saisir un responsable de chèque ", {
          containerId: "A",
        })
      }else if( nameBnque == ""){
        toast.error("⛔ Veuillez saisir le nom du banque ", {
          containerId: "A",
        })
      }
      else{
        const res = await API.post("payment/add", {
          user_id:user_id,
          etudiant_id:id,
          type_payment_id:selectTypePaiement.value,
          numero_cheque:numeroCheque,
          responsable_cheque:responsableCheque,
          date_cheque:convertDate,
          name_banque:nameBnque,
          prix:montant,
          type_prix:detail,
          prix_special:prixSpeciale,
          specialite_id:selectSpecialite.value,
          group_id:selectGroup.value
        }).then(res => {
          if(res.data.errNum ==400){
            toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
              containerId: "A",
            })
          }else if(res.data.errNum ==404){
            toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
              containerId: "A",
            })
          }else{
            props.history.push("/Etudiant")
          }
        })
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>


                          <CardTitle style={{color: "#556ee6"}} className="h4 mb-4">
                            Détail paiement etudiant :
                          </CardTitle>
                          {arrayDetail.map((el, index) => (
                              <Fragment key={index}>
                                  <Row key={index}>
                                      <h3>{el.specialite}</h3>
                                      <Col lg="6">
                                          <div className="mb-3">
                                              <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant total
                                                  : </Label>
                                              <AvField
                                                  name="montant"
                                                  placeholder="0.000"
                                                  type="text"
                                                  className="form-control"
                                                  value={el.prix_total}
                                                  disabled={true}
                                              />
                                          </div>
                                      </Col>
                                      <Col lg="3">
                                          <div className="mb-3">
                                              <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant Total payée
                                                  : </Label>
                                              <AvField
                                                  name="montant"
                                                  placeholder="0.000"
                                                  type="text"
                                                  className="form-control"
                                                  value={el.prix_total}
                                                  disabled={true}
                                              />
                                          </div>
                                      </Col>
                                      <Col lg="3">
                                          <div className="mb-3">
                                              <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant Reste à
                                                  payée: </Label>
                                              <AvField
                                                  name="montant"
                                                  placeholder="0.000"
                                                  type="text"
                                                  className="form-control"
                                                  value={el.prix_restant}
                                                  disabled={true}
                                              />
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row key={index}>
                                      <table className="table table-bordered mt-4">
                                          <thead>
                                          <tr>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Type Paiement
                                              </th>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Montant
                                              </th>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Numéro du chèque
                                              </th>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Date du chèque
                                              </th>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Nom du banque
                                              </th>
                                              <th style={{textAlign: "center"}} scope="col">
                                                  Responsable du chèque
                                              </th>
                                          </tr>
                                          </thead>
                                          {el.detail.map((el, index) => (
                                              <tbody key={index}>
                                              <tr>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.type_paiment}
                                                  </td>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.prix}
                                                  </td>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.numero_cheque}
                                                  </td>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.date_cheque}
                                                  </td>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.name_banque}
                                                  </td>
                                                  <td style={{textAlign: "center"}} className="col-md-2">
                                                      {el.responsable_cheque}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          ))}
                                      </table>
                                  </Row>
                              </Fragment>


                          ))}
                            <hr/>
                            <CardTitle style={{color: "#556ee6"}} className="h4 mb-4">
                                Paiement Etudiant :
                            </CardTitle>
                            <Row>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant total
                                            : </Label>
                                        <AvField
                                            name="montant"
                                            placeholder="0.000"
                                            type="text"
                                            className="form-control"
                                            value={montantTotal}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <div className="mb-3">
                                        <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant Total
                                            payée
                                            : </Label>
                                        <AvField
                                            name="montant"
                                            placeholder="0.000"
                                            type="text"
                                            className="form-control"
                                            value={montantPayee}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <div className="mb-3">
                                        <Label style={{color: "red"}} for="basicpill-firstname-input1">Montant Reste à
                                            payée: </Label>
                                        <AvField
                                            name="montant"
                                            placeholder="0.000"
                                            type="text"
                                            className="form-control"
                                            value={montantReste}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecialite}
                                    onChange={e => getGroup(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Groupe : </Label>
                                <Select
                                    options={group}
                                    isSearchable={true}
                                    value={selectGroup}
                                    onChange={e => getDetail(e)}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <Label for="basicpill-firstname-input1">Type de prix : </Label>
                              <div style={{"margin-top": "10px"}} className="mb-3">
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline1"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={detail === 1}
                                      onChange={e => getPrix(1)}
                                      // onChange={e => setDetail(1)}
                                  />
                                  <Label style={{color: "green"}}
                                         className="form-check-label"
                                         htmlFor="customRadioInline1"
                                  >
                                    Prix spéialité
                                  </Label>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={detail === 2}
                                      onChange={e => getPrix(2)}
                                  />
                                  <Label style={{color: "green"}}
                                         className="form-check-label"
                                         htmlFor="customRadioInline2"
                                  >
                                    Prix spéciale
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col style={{display: etatprix}} lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Prix spéciale : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    onKeyUp={handleKeyUp}
                                    value={prixSpeciale}
                                    // onChange={e => setPrixSpeciale(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Type paiement : </Label>
                                <Select
                                    options={typePiement}
                                    isSearchable={true}
                                    value={selectTypePaiement}
                                    onChange={e => getType(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Montant : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={montant}
                                    onChange={e => setMontant(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display: etat}}>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Chèque :
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={dateCheque}
                                      onChange={e => setDateCheque(e)}
                                      dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Numéro de chèque : </Label>
                                <AvField
                                    name="numero_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNumeroCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display: etat}}>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Responsable de chèque : </Label>
                                <AvField
                                    name="responsable_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setResponsableCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom du banque : </Label>
                                <AvField
                                    name="nom_banque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNameBnque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>props.history.push("/Etudiant")}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  onClick={save}
                                  type="submit"
                                  className="btn btn-primary "
                              >
                                CONFIRMER
                              </button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaiementEtudiant
PaiementEtudiant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
