import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"

const AttestationPresence = props => {
  const [section, setSection] = useState(1)
  const [loading, setLoading] = useState(false)
  // Data Table
  const [orders, setOrders] = useState([])
  const [modalVal, setModalVal] = useState(false)
  const [idVal, setIdVal] = useState("")

  const toggleVal = id => {
    setModalVal(!modalVal)
    setIdVal(id)
  }
  const valide = async () => {
    const res = await API.post("scolarite/etudiant/demande/accept", {
      id: idVal,
    }).then(res => {
      setModalVal(!modalVal)
      const resD = API.post("scolarite/etudiant/demande/list", {
        id: 1,
      }).then(resD => {
        setOrders(resD.data.Demandes)
      })
      setLoading(true)
    })
  }
  const refuse = async () => {
    const res = await API.post("scolarite/etudiant/demande/deny", {
      id: idVal,
    }).then(res => {
      setModalVal(!modalVal)
      const resD = API.post("scolarite/etudiant/demande/list", {
        id: 1,
      }).then(resD => {
        setOrders(resD.data.Demandes)
      })
      setLoading(true)
    })
  }
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPage: 30,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "code",
      text: "CIN",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etudiant",
      text: "Etudiant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "groupe",
      text: "Groupe",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "raison",
      text: "Raison",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "langue",
      text: "Langue",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: "Action",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.state == 0 ? (
          <i
            style={{ color: "#143F6B", cursor: "pointer" }}
            onClick={() => toggleVal(row.id)}
            className="fas fa-cogs"
          ></i>
        ) : row.state == 2 ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => toggleVal(row.id)}
            className="fas fa-times"
          ></i>
        ) : (
          <i
            style={{ color: "#2ca67a", cursor: "pointer" }}
            className="fas fa-check"
          ></i>
        ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.post("scolarite/etudiant/demande/list", {
      id: 1,
    }).then(res => {
      setOrders(res.data.Demandes)
    })
    setLoading(true)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Attestation De Présence | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Attestation De Présence
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>
                <React.Fragment>
                  <div>
                    {loading ? (
                      <Row>
                        <Col xs="12">
                          <ToolkitProvider
                            keyField="id"
                            data={orders}
                            columns={Columns()}
                            search
                            bootstrap4
                          >
                            {toolkitProps => (
                              <div>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <BootstrapTable
                                  wrapperClasses="table-responsive"
                                  noDataIndication={() => <NoDataIndication />}
                                  striped={false}
                                  bordered={false}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"table-light"}
                                  hover
                                  pagination={paginationFactory(pageOptions)}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Col>
                      </Row>
                    ) : (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Spinner
                            type="grow"
                            className="ms-6"
                            color="primary"
                          />
                        </div>
                        <h4
                          style={{ textAlign: "center", marginTop: "2%" }}
                          className="ms-6"
                        >
                          {" "}
                          En Cours ...{" "}
                        </h4>
                      </div>
                    )}
                  </div>
                  <Modal isOpen={modalVal} toggle={toggleVal} centered={true}>
                    <div
                      className="modal-header"
                      style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                      toggle={toggleVal}
                    >
                      {props.t("Validation Attestation De Présence")}
                    </div>
                    <ModalBody>
                      <div
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          FontSize: "14px",
                          FontWeight: "700",
                          LineHeight: "18.375px",
                        }}
                      >
                        <div
                          className="hvr-push"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: "15px",
                          }}
                        >
                          <div>
                            <Button
                              className="btn btn-success"
                              onClick={valide}
                            >
                              <i
                                style={{ color: "white", paddingRight: "5px" }}
                                className="fas fa-check-circle"
                              ></i>
                              {props.t("Valider")}
                            </Button>
                          </div>
                          <div>
                            <Button className="btn btn-danger" onClick={refuse}>
                              <i
                                style={{ color: "white", paddingRight: "5px" }}
                                className="fas fa-ban"
                              ></i>
                              {props.t("Refuser")}
                            </Button>
                          </div>
                          <div>
                            <Button
                              onClick={toggleVal}
                              className="btn btn-warning"
                            >
                              <i
                                style={{ color: "white", paddingRight: "5px" }}
                                className="fas fa-times-circle"
                              ></i>
                              {props.t("Annuler")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </React.Fragment>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AttestationPresence))
AttestationPresence.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
